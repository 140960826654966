$(document).foundation();
$(document).ready(function() {

    initAnimateInView();
    initVerticalCenter();
    initSliders();
    initDatePicker();
    sliderVimeoVideo();
    initHeader();
    setHeaderTransparency();
    initBurgerMenu();
    initAccordions();
    changeAccommodationView();
    initBlogHover();
    initGallery();
    initMasonry();
    initPopup();
    initBlogAlternating();
    blogFilterClicked();
    initHistoryItemBackground();
    initBookingLangLink()
});

function initPopup() {
	// Uncomment to enable cookie saving popup state:
	var delay = $('#popup').data('popup-delay');
	if ( ($('#popup').length > 0) && (getCookie('popup') === '') ) {
		if ($('#popup').length > 0) {
			setTimeout(function () {
			$('#popup').foundation('open');
			setCookie('popup', 'seen', 1);
			}, delay*1000);
		}
    }
	
/*	var delay = $('#popup').data('popup-delay');
	setTimeout(function () {
		$('#popup').foundation('open');
	}, delay*1000); */

}


function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function initGallery() {

	$('.gallery__item-img').on('click', function () {
		// Dynamically load the gallery slider into a reveal popup

		var galleryImageIndex = $(this).data('gallery-index') || 0;

		var $modal = $('<div id="gallery-modal" class="gallery-modal full reveal loading" data-reveal><button class="gallery-modal__close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><div class="gallery-modal__slider"></div></div>');

        $('body').append($modal);

        $modal.find('.gallery-modal__slider').load($(this).parents('.gallery__container').data('gallery-slider'), function () {
           $modal.removeClass('loading').find('.gallery-modal__slider').slick({
              arrows: true,
              fade: true
          }).slick('slickGoTo', galleryImageIndex, false);
       });

        $('#gallery-modal').foundation();
        $modal.foundation('open');

        $modal.on('closed.zf.reveal', function () {
           $modal.remove();
       });
    });
}

function initMasonry() {
    if ( $(window).outerWidth() >= 640 ) {
        $('.gallery__container').masonry({
            itemSelector: '.gallery__item',
            percentPosition: true,
            columnWidth: '.gallery__sizer'
        });
    }

    $('.gallery .filter-controls .button').on('click', function() {
        setTimeout(function() {
            initMasonry();
        },500)
    })
};

function initBlogHover() {
    $('.blog__grid-item').on('mouseenter', function() {
        $(this).find('.blog__grid-item-info-description').addClass('on');
    })

    $('.blog__grid-item').on('mouseleave', function() {
        $(this).find('.blog__grid-item-info-description').removeClass('on');
    })

    $('.blog__grid-item-lrg').on('mouseenter', function() {
        $(this).find('.blog__grid-item-info-description').addClass('on');
    })

    $('.blog__grid-item-lrg').on('mouseleave', function() {
        $(this).find('.blog__grid-item-info-description').removeClass('on');
    })
}

function initBlogAlternating() {
    $('.blog__grid-item').each(function() {
        if( $(this).css('display') === 'block' ) {
            $(this).addClass('on');
        } else {
            $(this).removeClass('on');
        }

        if ( $('.blog__grid-item.on').length >= 2 ) {
            $('.blog__grid-item.on:odd .blog__grid-item-image').css("background-image", "url('assets/img/corner-top-right.svg')");
            $('.blog__grid-item.on:odd .blog__grid-item-image').css("background-size", "contain");
            $('.blog__grid-item.on:odd .blog__grid-item-image').css("background-repeat", "no-repeat");
            $('.blog__grid-item.on:odd .blog__grid-item-image').css("background-position", "top right");

            $('.blog__grid-item.on:even .blog__grid-item-image').css("background-image", "url('assets/img/corner-top-left.svg')");
            $('.blog__grid-item.on:even .blog__grid-item-image').css("background-size", "contain");
            $('.blog__grid-item.on:even .blog__grid-item-image').css("background-repeat", "no-repeat");
            $('.blog__grid-item.on:even .blog__grid-item-image').css("background-position", "top left");
        }
    })

}

function blogFilterClicked() {
    $('.blog__filters .button').on('click', function() {
        setTimeout(function() {
            initBlogAlternating();
        },200)
    })
}

function changeAccommodationView() {
    if( $('.accommodation-landing__grid-item').length % 2 === 1 ) {
        $('.accommodation-landing__grid-item').last().addClass('medium-offset-3');
    }

    $('.grid-view').on('click', function(e) {
        e.preventDefault();
        $(this).css('background-image', 'url("/assets/img/grid-view-on.svg")');
        $('.list-view').css('background-image', 'url("/assets/img/list-view-off.svg")');
        $('.accommodation-landing__grid').addClass('active');
        $('.accommodation-landing__listing').removeClass('active');
    })

    $('.list-view').on('click', function(e) {
        e.preventDefault();
        $(this).css('background-image', 'url("/assets/img/list-view-on.svg")');
        $('.grid-view').css('background-image', 'url("/assets/img/grid-view-off.svg")');
        $('.accommodation-landing__grid').removeClass('active');
        $('.accommodation-landing__listing').addClass('active');
    })
}

function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}



// Get more translations from here:
// https://github.com/fengyuanchen/datepicker/tree/master/i18n
$.fn.datepicker.languages['en-US'] = {
   format: 'mm/dd/yyyy'
};

$.fn.datepicker.languages['es-ES'] = {
    format: 'dd/mm/yyyy',
    days: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mié', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
    weekStart: 1,
    months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Oct', 'Nov', 'Dic']
  };
  
$.fn.datepicker.languages['fr-FR'] = {
  format: 'dd/mm/yyyy',
  days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
  daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
  daysMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
  weekStart: 1,
  months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
  monthsShort: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jun', 'Jui', 'Aoû', 'Sep', 'Oct', 'Nov', 'Dec']
};

$.fn.datepicker.languages['de-DE'] = {
  format: 'dd.mm.yyyy',
  days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
  daysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  daysMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  weekStart: 1,
  months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
  monthsShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']
};


if ( window.location.href.indexOf('/en/') > -1 ) {
    var datepickerLanguage = 'en-US';
    var monthNames = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
    var dayNames   = [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ];
   
   $('.booking__form form').find('input[name="locale"]').val('en-US');
} else if ( window.location.href.indexOf('/de/') > -1 ) {
   datepickerLanguage = 'de-DE';
   monthNames = $.fn.datepicker.languages['de-DE']['months'];
   dayNames = $.fn.datepicker.languages['de-DE']['days'];
   
   $('.booking__form form').find('input[name="locale"]').val('de-DE');
} else if ( window.location.href.indexOf('/fr/') > -1 ) {
   datepickerLanguage = 'fr-FR';
   monthNames = $.fn.datepicker.languages['fr-FR']['months'];
   dayNames = $.fn.datepicker.languages['fr-FR']['days'];
   
   $('.booking__form form').find('input[name="locale"]').val('fr-FR');
} else {
    datepickerLanguage = 'es-ES';
    monthNames = $.fn.datepicker.languages['es-ES']['months'];
    dayNames = $.fn.datepicker.languages['es-ES']['days'];
    
    $('.booking__form form').find('input[name="locale"]').val('es-ES');
 }


function initDatePicker() {
    $('.header__buttons-mobile-booking').on('click', function () {
        $('.booking__form form').submit();
    });

    var bookingContainer = $('.booking__form form');
    var nowTemp = new Date();
    var tomorrow = new Date();
        tomorrow.setDate(nowTemp.getDate() + 1);

    var $checkInField = bookingContainer.find('#check-in');
    var $checkOutField = bookingContainer.find('#check-out');

    $checkInField.on('hide.datepicker', function () {
        var checkInDate  = $checkInField.datepicker('getDate');
        var checkOutDate = $checkOutField.datepicker('getDate');
        var dayAfterCheckIn = new Date(checkInDate);
            dayAfterCheckIn.setDate(checkInDate.getDate() + 1);

        // Ensure checkout comes after checkin.
        // Otherwise it all gets a bit Restaurant at the End of the Universe-ey.
        if ( checkInDate >= checkOutDate ) {
            $checkOutField.datepicker('setDate', dayAfterCheckIn);
        }

        $checkOutField.datepicker('setStartDate', dayAfterCheckIn);

        $checkOutField[0].focus();
    });

    var checkin = $checkInField.datepicker({
        format: 'dd/mm',
        language: datepickerLanguage,
        autoPick: false,
        autoHide: true,
        date: null,
        startDate: nowTemp,
        pick: function (e) {
            // User-facing date fields
            $('.check-in-month-year').text( monthNames[e.date.getMonth()] + ' ' + e.date.getFullYear() );
            $('.check-in-day').text( dayNames[e.date.getDay()] );

            // Formatted date for the booking engine
            $('input[name="arrive"]').val( e.date.getFullYear() + '-' + pad( e.date.getMonth() + 1*1 , 2) + '-' + e.date.getDate() );
        }
    });

    var checkout = $checkOutField.datepicker({
        format: 'dd/mm',
        language: datepickerLanguage,
        autoPick: false,
        autoHide: true,
        date: null,
        startDate: tomorrow,
        pick: function (e) {
            // User-facing date fields
            $('.check-out-month-year').text( monthNames[e.date.getMonth()] + ' ' + e.date.getFullYear() );
            $('.check-out-day').text( dayNames[e.date.getDay()] );

            // Formatted date for the booking engine
            $('input[name="depart"]').val( e.date.getFullYear() + '-' + pad( e.date.getMonth() + 1*1 , 2) + '-' + e.date.getDate() );
        }
    });
}

function initBurgerMenu() {

    var sticky = $('.sticky'),
    scrollOffset = $(window).scrollTop();

    $('.header__open').on('click', function() {
        $(this).css('display','none');
        $('.header__close').css('display','block');
        $('.header__logo .logo').css('opacity', '0');
        sticky.addClass('fixed');
        $('.header__logo .logo-blue').css('opacity', '1');
    })

    $('.header__close').on('click', function() {
        $(this).css('display', 'none');
        $('.header__open').css('display', 'block');
        $('.header__logo .logo').css('opacity', '0');
        $('.header__logo .logo-blue').css('opacity', '1');
    })
}

function initSelect() {
    $('select').customSelect();
}

function initHeader() {
	if ( $('.slider').length === 0 ) {
		$('body').addClass('no-slider');
	}

    if ( $('body.no-slider').length > 0 ) {
        $('.header__buttons .white-outline, .header__buttons-mobile .white-outline').addClass('stuck');
    }

    setHeaderTransparency();
    $(window).scroll(setHeaderTransparency);
}

function initAccordions() {

    $('.burger-menu__accordion').accordiom({
        showFirstItem: false
    })

    $('.booking__accordion').accordiom({
        showFirstItem: false,
        buttonBelowContent: true
    })

    $('.booking__accordion .accordionContent img').on('click', function() {
        $('.booking__accordion .accordionButton').trigger('click');
    })

    $('.book-direct').on('click', function(e) {
        e.preventDefault();
        $('.booking__accordion .accordionButton').trigger('click');
    })

    $('.terms-conditions__accordion').accordiom({
        showFirstItem: false
    })

    $('.events__accordion-container').accordiom({
        showFirstItem: false,
        buttonBelowContent: false
    });

    $('.accordion__container').accordiom({
        showFirstItem: false
    })

    $('.blog__categories-mobile').accordiom({
        showFirstItem: false
    })
}

function setHeaderTransparency() {
    var sticky = $('.sticky'),
    scrollOffset = $(window).scrollTop();

    if ( $('body.no-slider').length > 0 ) {

        if (scrollOffset >= 30) {
            sticky.addClass('fixed');
            $('.header__logo .logo-blue').css('opacity', '1');

        } else {
            sticky.removeClass('fixed');
            $('.header__logo .logo-blue').css('opacity', '1');
        }

    } else {

        if (scrollOffset >= 180) {
            sticky.addClass('fixed');
            $('.header__logo .logo').css('opacity', '0');
            $('.header__logo .logo-blue').css('opacity', '1');
            $('.header__buttons .white-outline, .header__buttons-mobile .white-outline').addClass('stuck');
        } else {
            sticky.removeClass('fixed');
            $('.header__logo .logo').css('opacity', '1');
            $('.header__logo .logo-blue').css('opacity', '0');
            $('.header__buttons .white-outline, .header__buttons-mobile .white-outline').removeClass('stuck');
        }
    }
}

function sliderVimeoVideo() {
    if ( $('.slider__slide .iframe-wrap').length > 0 ) {
        var iframePlayer = $('iframe#vimeo-player');
        var player = new Vimeo.Player(iframePlayer);

        $('.slider__slide .play-button').click(function() {
            var playButton = $(this);
            player.getPaused().then(function(paused) {
                if ( paused == true ) {
                    player.play();
                    playButton.hide();
                    $('.video-bar').css('display', 'block');
                }
            })
        });

        $('.video-bar input[type="checkbox"]').on('change', function() {
            player.getVolume().then(function(volume) {
                if ( volume == 0  ) {
                    player.setVolume(0.66);
                    $('#volumeSwitch').toggleClass('on');
                    $('#volumeSwitch').toggleClass('off');
                    $('#volumeOff').css('visibility', 'hidden');
                    $('#volumeOn').css('visibility', 'visible');
                    $("#volumeSwitch").checked = true;
                } else {
                    player.setVolume(0);
                    $('#volumeSwitch').toggleClass('on');
                    $('#volumeSwitch').toggleClass('off');
                    $('#volumeOff').css('visibility', 'visible');
                    $('#volumeOn').css('visibility', 'hidden');
                    $("#volumeSwitch").checked = false;
                }
            })
        })
    }
}

function initSliders() {

    $('.slider__inner').reformSlider({
        autoPlay: true,
        speed: 3000,
        animationSpeed: 500,
        arrows: false,
        pagination: true
    });
    
    if ( $('.slider__slide iframe').length > 0 ) {
        coverViewport('.slider__slide iframe', true);
        $(window).on('resize', function () {
            coverViewport('.slider__slide iframe', true);
        });
    }

    $('.alternative-rooms__slider').slick({
        arrows: false,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    arrows: true,
                    dots: false,
                    infinite: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 900,
                settings: {
                    arrows: true,
                    dots: false,
                    infinite: false,
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    })

    $('.blog__featured-slider').slick({
        arrows: true,
        dots: false,
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    arrows: false,
                    dots: false,
                    autoplay: true,
                    autoplaySpeed: 4000
                }
            }
        ]
    })
}

function initVerticalCenter() {
    $('.vertical-center').each(function () {
        $(this).wrapInner('<div class="vertical-center-table"><div class="vertical-center-row"><div class="vertical-center-cell"></div></div></div>');
    });
}

function initAnimateInView() {
    $(window).on('DOMContentLoaded load resize scroll', animateElementsInView);
    animateElementsInView();
}

function animateElementsInView() {
    $('[data-animate-in-view]').each(function() {
        //console.log($(this));
        if (isElementInViewport($(this))) {
            $(this).addClass($(this).data('animate-in-view'));
        }
    });
}

// https://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
function isElementInViewport (el) {
    //special bonus for those using jQuery
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    var rect = el.getBoundingClientRect();
    
    return (
        rect.top <=  $(window).scrollTop() + window.innerHeight
        );
}

/**
 * Scales any element to simulate the CSS background-size: cover property.
 * @param {DOMelement} element The element to scale
 * @param {bool} [scaleToParent] Scale the element to fill the parent instead
 *                               of the whole browser window.
 */
 function coverViewport(element, scaleToParent) {
    if (typeof scaleToParent === 'undefined') { scaleToParent = false; }

    var viewportWidth, viewportHeight;
    if (scaleToParent) {
        viewportWidth = $(element).parent().innerWidth();
        viewportHeight = $(element).parent().innerHeight();
    } else {
        viewportWidth = $(window).innerWidth();
        viewportHeight = $(window).innerHeight();
    }

    var $element = $(element);

    var elementWidth = $element.attr('width');
    var elementHeight = $element.attr('height');

    // Aspect ratio = height / width
    var viewportRatio = viewportHeight / viewportWidth;
    var videoRatio = elementHeight / elementWidth;

    //console.log('Viewport: ', viewportRatio, ' Video: ', videoRatio);

    var newWidth = (elementWidth / elementHeight) * viewportHeight
    var newHeight = (elementHeight / elementWidth) * viewportWidth;
    var newTop = 0;

    if (viewportRatio > videoRatio) {
        // set video height to the viewport, and extend the video beyond the viewport width, i.e. portrait viewport
        //console.log('crop left and right');
        $element.css('height', viewportHeight);
        $element.css('width', newWidth);
        $element.css('left', '-' + (newWidth - viewportWidth) / 2 + 'px');
        $element.css('top', '0');
    } else if (viewportRatio < videoRatio) {
        // set video width to the viewport, and extend the video beyond the viewport height, i.e. landscape viewport
        //console.log('crop top and bottom');
        $element.css('width', viewportWidth);
        $element.css('height', newHeight);
        $element.css('left', '0');
        $element.css('top', '-' + (newHeight - viewportHeight) / 2 + 'px');
    } else {
        // set the video width AND height to the viewport size, i.e. viewport is the same ratio as the video
        //console.log('no crop');
        $element.css('height', viewportHeight);
        $element.css('width', viewportWidth);
        $element.css('left', '0');
        $element.css('top', '0');
    }
}

// https://stackoverflow.com/questions/24933430/img-src-svg-changing-the-fill-color
function inlineSVGs(inputValue) {
    var selectorString = '';
    if (typeof inputValue === 'object') {
        selectorString = inputValue.join(',');
    } else {
        selectorString = inputValue;
    }

    $(selectorString).each(function() {
        var $img = $(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        if (imgURL.indexOf('.svg') > -1) {
            $.get(
                imgURL,
                function(data) {
                    // Get the SVG tag, ignore the rest
                    var $svg = $(data).find('svg');

                    // Add replaced image's ID to the new SVG
                    if (typeof imgID !== 'undefined') {
                        $svg = $svg.attr('id', imgID);
                    }
                    // Add replaced image's classes to the new SVG
                    if (typeof imgClass !== 'undefined') {
                        $svg = $svg.attr('class', imgClass + ' replaced-svg');
                    }

                    // Remove any invalid XML tags as per http://validator.w3.org
                    $svg = $svg.removeAttr('xmlns:a');

                    // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
                    if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                        $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
                    }

                    // Replace image with new SVG
                    $img.replaceWith($svg);
                },
                'xml'
                );
        }
    });
}


function initHistoryItemBackground() {
  $('.image-and-feature-text__text').each(function () {
    if ( typeof $(this).attr('style') !== 'undefined' ) {
      if ( $(this).attr('style').indexOf('background-image') > -1 ) {
        $(this).addClass('has-background-image');
      }
    }
  });
}


function initBookingLangLink() {
    var langBtn = $('.header__language-dropdown ul li a')
    var props = {
        langs: {
            en: "en-US",
            es: "es-ES"
        },
        input: $('.booking__form form').find('input[name="locale"]')
    }

    function setLang (props) {
        if ( window.location.href.indexOf('/en/') > -1 
            || window.location.href.indexOf('/de/') > -1 
            || window.location.href.indexOf('/fr/') > -1 ) {
            props.input.val(props.langs.en);
            return;
        }

        props.input.val(props.langs.es);
    }

    langBtn.on('click', function () {
        setLang(props)
    })
    setLang(props)
}