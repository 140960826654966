/*
 * Filter Anything v1.0.0 ~ Sun Siyam events Edition
 * https://github.com/80DAYS/Filter-Anything
 */
 $(document).ready( function () {
 	$('[data-events-filter]').each( function () {
 		var $_filterElement = $(this);
 		var $_filterItems = $_filterElement.find( '.' + $_filterElement.data('events-filter-class') );
 		var $_guestFilterItems = $_filterElement.find( '.' + $_filterElement.data('events-guest-filter-class') );


		// Step 1: assemble a full list of each 
		var filterCategories = '';
		$_filterItems.each(function () {
			if (typeof $(this).data('events-filter-categories') !== 'undefined') {
				filterCategories += $(this).data('events-filter-categories') + '|';
			}
		});
		filterCategories = filterCategories.substring(0, filterCategories.length - 1); // remove trailing |
		filterCategories = filterCategories.split('|'); // make it an array
		filterCategories = unique(filterCategories); // remove duplicates

		//guest list
		var guestFilterCategories = '';
		$_guestFilterItems.each(function () {
			if (typeof $(this).data('events-guest-filter-categories') !== 'undefined') {
				guestFilterCategories += $(this).data('events-guest-filter-categories') + '|';
			}
		});
		guestFilterCategories = guestFilterCategories.substring(0, guestFilterCategories.length - 1); // remove trailing |
		guestFilterCategories = guestFilterCategories.split('|'); // make it an array
		guestFilterCategories = unique(guestFilterCategories); // remove duplicates
		
		
		// Step 2: create the filter buttons with an optional dropdown on mobile
		var hasfilterDropdown = false;
		var filterDropdown = '';
		var buttonClass = '';
		
		if (typeof $_filterElement.data('events-filter-controls-select-dropdown-mobile') !== 'undefined') {
			hasfilterDropdown = true;
			buttonClass = 'hide-for-small-only';
			filterDropdown = '<div class="events-filter-controls-dropdown show-for-small-only"><select autocomplete="off"><option value="clear">Clear</option>';
		}
		
		var filterButtons = '<button class="button all on ' + buttonClass + '">clear</button>';
		
		for (var category in filterCategories) {
			filterButtons += '<button class="button ' + buttonClass + '" data-events-filter-category="' + filterCategories[category] + '">' + filterCategories[category] + '</button>';
			
			if (hasfilterDropdown) {
				filterDropdown += '<option value="' + filterCategories[category] + '">' + filterCategories[category] + '</option>';
			}
		}

		var filterApply = '<div class="hide-for-medium text-center"><button class="set-filters book-button"><span class="apply-filter">apply filter</span></button></div>';
		
		$_filterElement.find( '.' + $_filterElement.data('events-filter-controls') ).append(filterButtons).append(filterApply);
		
		if (hasfilterDropdown) {
			filterDropdown += '</select></div>';
			$_filterElement.find( '.' + $_filterElement.data('events-filter-controls') ).append(filterDropdown);
		}

		$_filterItems.fadeIn();



		// guest buttons
		var hasguestfilterDropdown = false;
		var guestFilterDropdown = '';
		var guestButtonClass = '';
		
		if (typeof $_filterElement.data('events-guest-filter-controls-select-dropdown-mobile') !== 'undefined') {
			hasguestfilterDropdown = true;
			guestButtonClass = 'hide-for-small-only';
			guestFilterDropdown = '<div class="events-guest-filter-controls-dropdown show-for-small-only"><select autocomplete="off"><option value="clear">Clear</option>';
		}
		
		var guestFilterButtons = '<button class="button all on ' + guestButtonClass + '">clear</button>';
		
		for (var category in guestFilterCategories) {
			guestFilterButtons += '<button class="button ' + guestButtonClass + '" data-events-guest-filter-category="' + guestFilterCategories[category] + '">' + guestFilterCategories[category] + '</button>';
			
			if (hasguestfilterDropdown) {
				guestFilterDropdown += '<option value="' + guestFilterCategories[category] + '">' + guestFilterCategories[category] + '</option>';
			}
		}

		var filterApply = '<div class="hide-for-medium text-center"><button class="set-filters book-button"><span class="apply-filter">apply filter</span></button></div>';
		
		$_filterElement.find( '.' + $_filterElement.data('events-guest-filter-controls') ).append(guestFilterButtons).append(filterApply);
		
		if (hasguestfilterDropdown) {
			guestFilterDropdown += '</select></div>';
			$_filterElement.find( '.' + $_filterElement.data('events-guest-filter-controls') ).append(guestFilterDropdown);
		}

		$_guestFilterItems.fadeIn();


		
		
		// Step 4: set up the button events
		var $_filterButtons = $_filterElement.find( '.' + $_filterElement.data('events-filter-controls') ).find('button').add($_filterElement.find( '.' + $_filterElement.data('events-guest-filter-controls') ).find('button'));
		var filterCategoryType = new Array();
		var filterCategoryguest = new Array();
		var allFilters = $_filterItems.add($_guestFilterItems);

		$_filterButtons.on('click', function () {
			// $(this).siblings().removeClass('on');
			
			// Clear the "All" button
			if (!$(this).hasClass('all')) {
				$_filterButtons.filter('.all').removeClass('on');
				$(this).addClass('on');
				if ( $(this).parent().hasClass("events-type-content") ) {
					filterCategoryType.push($(this).data('events-filter-category'));
				} else {
					filterCategoryguest.push($(this).data('events-guest-filter-category'));
				}
			} else {
				// All button has been clicked, so show all buttons and return
				$_filterButtons.removeClass('on');
				$_filterButtons.filter('.all').addClass('on');
				$_filterElement.find('.events-filter-controls-dropdown select').val('all');
				filterCategoryType = [];
				filterCategoryguest = [];
				$_filterItems.fadeOut();
				$_filterItems.fadeIn();
				return;
			}

			if ( $(window).width() > 640 ) {
			// Only show the items that match the filter

			$('.events__grid-item').each(function () {
				$(this).fadeOut();

				if ( (filterCategoryType.length > 0) && (filterCategoryguest.length > 0) ) {
					var shouldShowType = false;
					var shouldShowguest = false;
					var i;
					for ( i = 0; i < filterCategoryType.length; ++i ) {
						if ( $(this).data('events-filter-categories').indexOf(filterCategoryType[i]) > -1) {
							shouldShowType = true;
						}
					}

					for ( i = 0; i < filterCategoryguest.length; ++i ) {
						if ( $(this).data('events-guest-filter-categories').indexOf(filterCategoryguest[i]) > -1) {
							shouldShowguest = true;
						}
					}

					if ( shouldShowType && shouldShowguest ) {
						$(this).fadeIn();
					}

				} else {

					var i;
					for ( i = 0; i < filterCategoryType.length; ++i ) {
						if ( $(this).data('events-filter-categories').indexOf(filterCategoryType[i]) > -1) {
							$(this).fadeIn();
						}
					}

					for ( i = 0; i < filterCategoryguest.length; ++i ) {
						if ( $(this).data('events-guest-filter-categories').indexOf(filterCategoryguest[i]) > -1) {
							$(this).fadeIn();
						}
					}
				}		
			});
		}


		// Update the mobile dropdown
		if (($(window).innerWidth() > 640) && (hasfilterDropdown)) {
			$_filterElement.find('.events-filter-controls-dropdown select').val($(this).data('events-filter-category'));
		}
	});

		if ( $(window).width() <= 640 ) {
			$('.set-filters').click(function() {
			// Only show the items that match the filter
			allFilters.each(function () {
				$(this).fadeOut();

				if (typeof $(this).data('events-filter-categories') || $(this).data('events-guest-filter-categories') !== 'undefined') {
					var i;
					for ( i = 0; i < filterCategoryType.length; ++i ) {
						if ( $(this).data('events-filter-categories').indexOf(filterCategoryType[i]) > -1) {
							$(this).fadeIn();
						}
						if ( $(this).data('events-guest-filter-categories').indexOf(filterCategoryType[i]) > -1) {
							$(this).fadeIn();
						}
					}

					for ( i = 0; i < filterCategoryguest.length; ++i ) {
						if ( $(this).data('events-filter-categories').indexOf(filterCategoryguest[i]) > -1) {
							$(this).fadeIn();
						}
						if ( $(this).data('events-guest-filter-categories').indexOf(filterCategoryguest[i]) > -1) {
							$(this).fadeIn();
						}
					}
				}
			});
		});
		}		


		// Step 5: Set up the events for the dropdown menu
		if (hasfilterDropdown) {
			$_filterElement.find('.events-filter-controls-dropdown select').on('change', function () {
				if ($(window).innerWidth() <= 640) {
					if ( $(this).val() === 'all' ) {
						$_filterElement.find( '.' + $_filterElement.data('events-filter-controls') ).find('button.all').trigger('click');
					} else {
						$_filterElement.find( '.' + $_filterElement.data('events-filter-controls') ).find('button[data-events-filter-category="' + $(this).val() + '"]').trigger('click');
					}
				}
			});
		}
	});
});


// Filter a list to only unique values
// https://stackoverflow.com/questions/12551635/jquery-remove-duplicates-from-an-array-of-strings
function unique(list) {
	var result = [];
	$.each(list, function(i, e) {
		if ($.inArray(e, result) == -1) result.push(e);
	});
	return result;
}