jQuery(document).ready(function(){

    if ( $('.location-map').length > 0 ){
	
	var styledMap = new google.maps.StyledMapType(princesaPlazaMap ,{name: "Styled Map"});
	
	
  var map = new google.maps.Map(jQuery('#mapLocationSrc').get(0),{
      center: new google.maps.LatLng(latitude, longitude),
      zoom: zoomLevel,
	  scrollwheel: false,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP
  }),
  marker = new google.maps.Marker({
      position: new google.maps.LatLng(latitude, longitude),
      icon: markerIcon,
      map: map
  });
  
  if ( $('#map-marker-content').length > 0 ) {
    var info = new google.maps.InfoWindow({
      content: $('#map-marker-content').html()
    });
    //info.open(map, marker);
    
    marker.addListener('mouseover', function() {
      info.open(map, this);
    });
    
    marker.addListener('click', function() {
      var mapsLinks = $('#map-marker-link').attr('href');
      if (typeof mapsLinks != 'undefined') {
        window.open(mapsLinks, "_blank") || window.location.replace(mapsLinks);
      }
    });
  }
  
  
  map.mapTypes.set('map_style', styledMap);
  map.setMapTypeId('map_style');
  
  var old_v='',
    ds=new google.maps.DirectionsService(),
    dr=new google.maps.DirectionsRenderer();
  dr.setMap(map);
  dr.setPanel(jQuery('#map-directions-data').get(0));  
  
    }
});

var princesaPlazaMap = [
    {
        "featureType": "administrative",
        "elementType": "labels",
        "stylers": [
            {
                "color": "#a07400"
            },
            {
                "weight": "0.01"
            }
        ]
    },
    {
        "featureType": "administrative.locality",
        "elementType": "all",
        "stylers": [
            {
                "saturation": "13"
            },
            {
                "lightness": 19
            },
            {
                "visibility": "off"
            },
            {
                "hue": "#0049ff"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 100
            },
            {
                "visibility": "simplified"
            },
            {
                "hue": "#ff0000"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f8f8f8"
            },
            {
                "saturation": "-34"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "hue": "#ff0000"
            },
            {
                "saturation": -100
            },
            {
                "lightness": 100
            },
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
            {
                "hue": "#008eff"
            },
            {
                "saturation": "-69"
            },
            {
                "lightness": 31
            },
            {
                "visibility": "simplified"
            },
            {
                "gamma": "1.07"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
            {
                "hue": "#bbc0c4"
            },
            {
                "saturation": -93
            },
            {
                "lightness": 31
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels",
        "stylers": [
            {
                "hue": "#bbc0c4"
            },
            {
                "saturation": -93
            },
            {
                "lightness": -2
            },
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [
            {
                "hue": "#e9ebed"
            },
            {
                "saturation": -90
            },
            {
                "lightness": -8
            },
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "saturation": "-59"
            },
            {
                "lightness": "75"
            },
            {
                "visibility": "on"
            },
            {
                "weight": "0.01"
            },
            {
                "hue": "#0050ff"
            },
            {
                "gamma": "0.30"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "saturation": "-85"
            },
            {
                "lightness": "-27"
            },
            {
                "visibility": "simplified"
            },
            {
                "gamma": "1.24"
            },
            {
                "hue": "#008dff"
            }
        ]
    }
]